<template>
    <ui-create
        name="costsCenters"
        v-model="form"
        :title="title"
        :id="id"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwcostCenterCreate',
    components: {
        uiCreate,
        uiCrud
    },
    data(){
        return {
            form: {
                descricao: '',
                date: null
            },
            rows: [
                [
                    {
                        name: 'descricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Descrição',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    },  
                    {
                        name: 'date',
                        value: '',
                        type: 'string',
                        field: 'date-picker',
                        label: 'Descrição',
                        handle: 'default',
                        fieldModel: false,
                    },
                ]
            ]
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id || 0
        },

        title(){
            return this.id ? `Editar Centro de Custo: ${this.id}` : 'Cadastrar Centro de Custo'
        }
    },

    mounted(){
        this.$root.$on('load-data-costsCenters', (data) => {
            this.form = data
        })
    }
}
</script>